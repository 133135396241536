<template>
  <div id="app">
    <!-- ======= Header ======= -->
    <header id="header" class="fixed-top d-flex align-items-center">
      <div class="container d-flex justify-content-between align-items-center">
        <div id="logo">
          <a href="/">
            <img src="/assets/img/icon.png" alt="" class="icon" />
          </a>
        </div>

        <nav id="navbar" class="navbar">
          <ul>
            <li>
              <a class="" href="/portfolio">Portfolio</a>
            </li>
            <li>
              <a class="" href="/veterans">Hometown Veterans</a>
            </li>
            <li>
              <a class="" href="/about">About</a>
            </li>
            <li>
              <a class="" href="/contact">Contact</a>
            </li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
        <!-- .navbar -->
      </div>
    </header>
    <!-- End Header -->

    <router-view></router-view>

    <!-- ======= Footer ======= -->
    <footer id="footer">
      <!-- <div class="footer-top">
        <div class="container"></div>
      </div> -->

      <div class="container">
        <div class="row">
          <div class="col-auto col-sm-3">
            <a href="/contact" class="btn-foot">Contact</a>
          </div>
          <div class="col-auto col-sm-6">
            <div class="copyright">
              &copy; Copyright <strong>Don Ryan</strong>. All Rights Reserved
            </div>
            <div class="credits">
              Designed by
              <a href="https://dave-ryan.github.io/" target="_blank"
                >Dave Ryan</a
              >
            </div>
          </div>
          <div class="col-auto col-sm-3"></div>
        </div>
      </div>
    </footer>
    <!-- End Footer -->
    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>

<style scoped>
.icon {
  max-width: 20em;
}
.btn-foot {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}
.btn-foot:hover {
  background: rgba(0, 0, 0, 0.9);
  border: 2px solid rgba(1, 114, 196, 0.9);
}
</style>

<script>
export default {
  name: "App",
  components: {},
};
</script>
