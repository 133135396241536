<template>
  <div id="portfolio">
    <!-- ======= Portfolio Section ======= -->
    <section id="portfolio" class="portfolio">
      <div class="container" data-aos="fade-up">
        <div class="section-header">
          <!-- <h3 class="section-title">Portfolio</h3> -->
          <!-- <p class="section-description">TEST!</p> -->
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" class="filter-active">All</li>
              <li data-filter=".portrait">Portrait</li>
              <li data-filter=".landscape">Landscape</li>
              <li data-filter=".still-life">Still Life</li>
              <li data-filter=".charcoal">Charcoal</li>
              <li data-filter=".oil">Oil</li>
              <li data-filter=".pastel">Pastel</li>
              <li data-filter=".watercolor">Watercolor</li>
            </ul>
          </div>
        </div>

        <div class="row portfolio-container">
          <div v-for="image in images" :key="image.source" :class="image.type">
            <a
              :href="image.source"
              data-gallery="portfolioGallery"
              class="portfolio-lightbox preview-link"
              :title="image.title"
            >
              <img :src="image.source" class="img-fluid rounded" alt="" />
              <div class="portfolio-info"></div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- End Portfolio Section -->
  </div>
</template>

<style scoped>
.container {
  padding-top: 100px;
}
h4 {
  color: slategray;
}
img {
  height: 20em;
  width: 20em;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 7px 10px #888888;
  border-radius: 20px;
}
</style>


<script>
export default {
  created: function () {
    for (let i = this.images.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.images[i], this.images[j]] = [this.images[j], this.images[i]];
    }

    // for (let i = this.images.length - 1; i > 0; i - 3) {
    //   this.column1.push(this.images[i]);
    //   this.column2.push(this.images[i + 1]);
    //   this.column3.push(this.images[i + 2]);
    // }
    // console.log(this.column1);
  },
  data: function () {
    return {
      column1: [],
      column2: [],
      column3: [],
      images: [
        {
          title: "No Room",
          type: "col-lg-4 col-md-6 portfolio-item p-2 landscape oil",
          source: "assets/img/Landscape/Oil/No Room 10x20.jpg",
        },
        {
          title: "Open Sea",
          type: "col-lg-4 col-md-6 portfolio-item p-2 landscape oil",
          source: "assets/img/Landscape/Oil/Open Sea 16x12.jpg",
        },
        {
          title: "Tractor",
          type: "col-lg-4 col-md-6 portfolio-item p-2 landscape oil",
          source: "assets/img/Landscape/Oil/Tractor 12x16.jpg",
        },
        {
          title: "Vacation House",
          type: "col-lg-4 col-md-6 portfolio-item p-2 landscape oil",
          source: "assets/img/Landscape/Oil/Vacation House 12x16.jpg",
        },
        {
          title: "Break Out",
          type: "col-lg-4 col-md-6 portfolio-item p-2 landscape pastel",
          source: "assets/img/Landscape/Pastel/Break Out 12x16.jpg",
        },
        {
          title: "Church Yard",
          type: "col-lg-4 col-md-6 portfolio-item p-2 landscape pastel",
          source: "assets/img/Landscape/Pastel/Church Yard 16x12.jpg",
        },
        {
          title: "Three Pine Island",
          type: "col-lg-4 col-md-6 portfolio-item p-2 landscape pastel",
          source: "assets/img/Landscape/Pastel/Three Pine Island 12x16.jpg",
        },
        {
          title: "Tree",
          type: "col-lg-4 col-md-6 portfolio-item p-2 landscape watercolor",
          source: "assets/img/Landscape/Watercolor/Tree 12x20.jpg",
        },
        {
          title: "Apple",
          type: "col-lg-4 col-md-6 portfolio-item p-2 still-life oil",
          source: "assets/img/Still Life/Oil/Apple 6x9.jpg",
        },
        {
          title: "Blue Pitcher",
          type: "col-lg-4 col-md-6 portfolio-item p-2 still-life oil",
          source: "assets/img/Still Life/Oil/Blue Pitcher 6x6.jpg",
        },
        {
          title: "Helens Fairy",
          type: "col-lg-4 col-md-6 portfolio-item p-2 still-life oil",
          source: "assets/img/Still Life/Oil/Helens Fairy 12x16.jpg",
        },
        {
          title: "Thankful Bounty",
          type: "col-lg-4 col-md-6 portfolio-item p-2 still-life oil",
          source: "assets/img/Still Life/Oil/Thankful Bounty 12x16.jpg",
        },
        {
          title: "Harley",
          type: "col-lg-4 col-md-6 portfolio-item p-2 still-life pastel",
          source: "assets/img/Still Life/Pastel/Harley 12x16.jpg",
        },
        {
          title: "Wild Iris",
          type: "col-lg-4 col-md-6 portfolio-item p-2 still-life watercolor",
          source: "assets/img/Still Life/Watercolor/Wild Iris 13x18.jpg",
        },
        {
          title: "After Vincent van Gogh",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait charcoal",
          source:
            "assets/img/Portrait Figure/Charcoal/After Vincent Van Gagh 20x16.jpg",
        },
        {
          title: "Cowboy",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait charcoal",
          source: "assets/img/Portrait Figure/Charcoal/Cowboy 20x16.jpg",
        },
        {
          title: "Head Study",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait charcoal",
          source: "assets/img/Portrait Figure/Charcoal/Head Study 12x16.jpg",
        },
        {
          title: "Quick Pose",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait charcoal",
          source: "assets/img/Portrait Figure/Charcoal/Quick Pose 20x16.jpg",
        },
        {
          title: "Santas Thought",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait charcoal",
          source:
            "assets/img/Portrait Figure/Charcoal/Santas Thought 20x16.jpg",
        },
        {
          title: "Study",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait charcoal",
          source: "assets/img/Portrait Figure/Charcoal/Study 20x16.jpg",
        },
        {
          title: "Lenin D",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait oil",
          source: "assets/img/Portrait Figure/Oil/Lenin D 16x12.jpg",
        },
        {
          title: "Sculptor",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait oil",
          source: "assets/img/Portrait Figure/Oil/Sculptor 16x12.jpg",
        },
        {
          title: "Self Portrait",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait oil",
          source: "assets/img/Portrait Figure/Oil/Self Portrait 16x2.jpg",
        },
        {
          title: "Andromida's Pain",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait pastel",
          source: "assets/img/Portrait Figure/Pastel/Andromidas Pain 20x16.jpg",
        },
        {
          title: "Blue Heron",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait pastel",
          source: "assets/img/Portrait Figure/Pastel/Blue Heron18x18.jpg",
        },
        {
          title: "Brother Steve",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait pastel",
          source: "assets/img/Portrait Figure/Pastel/Brother Steve 20x16.jpg",
        },
        {
          title: "Charles",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait pastel",
          source: "assets/img/Portrait Figure/Pastel/Charles.jpg",
        },
        {
          title: "Daryl",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait pastel",
          source: "assets/img/Portrait Figure/Pastel/Daryl 20x16.jpg",
        },
        {
          title: "Longhorn",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait pastel",
          source: "assets/img/Portrait Figure/Pastel/Longhorn 20x16.jpg",
        },
        {
          title: "Princess",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait pastel",
          source: "assets/img/Portrait Figure/Pastel/Princess 16x16.jpg",
        },
        {
          title: "Summer Fun",
          type: "col-lg-4 col-md-6 portfolio-item p-2 portrait pastel",
          source: "assets/img/Portrait Figure/Pastel/Summer Fun 16x12.jpg",
        },
      ],
    };
  },
  methods: {},
};
</script>