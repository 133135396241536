<template>
  <div>
    <!-- ======= Hero Section ======= -->
    <section id="hero">
      <div class="hero-container" data-aos="zoom-in" data-aos-delay="100">
        <h1>Don Ryan</h1>
        <h2>Chicago based artist and veteran</h2>
        <a href="/portfolio" class="btn-get-started">My Artwork</a>
        <a href="/veterans" class="btn-get-started"
          >Hometown Veterans</a
        >
      </div>
    </section>
    <!-- End Hero Section -->
  </div>
</template>

<script>
export default {};
</script>
