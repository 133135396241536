<template>
  <div id="portfolio">
    <!-- ======= Portfolio Section ======= -->
    <section id="portfolio" class="portfolio">
      <div class="container" data-aos="fade-up">
        <div class="section-header"></div>
        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-2"></div>
          <div class="col-lg-8 d-flex justify-content-center">
            <p>
              The Hometown Veterans collection represents years of hard work
              documenting veterans in Oil, Pencil, Watercolor, and Pastel. Don
              met with local veterans that wished to be painted and created
              their portraits. This is small a selection of that project,
              showcasing both Don Ryan's skills as an artist, and the souls of
              these brave men & women.
            </p>
          </div>
          <div class="col-2"></div>
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-2"></div>
          <div class="col-lg-8 d-flex justify-content-center fw-bold">
            <p>
              This incredible collection of over 30 portraits was displayed at
              the
              <a
                href="https://maps.app.goo.gl/FPiRwdRzQePAbG548"
                target="_blank"
                >Palette & Chisel Art Academy in Chicago</a
              >. The show was open to the public, and the grand exhibition was
              shown on Veteran's Day '23.
            </p>
          </div>
          <div class="col-2"></div>
        </div>

        <div class="row portfolio-container">
          <div v-for="image in veterans" :key="image.source">
            <div :class="image.type">
              <a
                :href="image.source"
                data-gallery="portfolioGallery"
                class="portfolio-lightbox preview-link"
                :title="image.title"
              >
                <img :src="image.source" class="img-fluid rounded" alt="" />
                <div class="portfolio-info"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Portfolio Section -->
  </div>
</template>

<style scoped>
.container {
  padding-top: 100px;
}
h4 {
  color: slategray;
}
img {
  max-width: 300px;
  box-shadow: 0px 7px 10px #888888;
  border-radius: 20px;
}
p {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
</style>

<script>
export default {
  data: function () {
    return {
      veterans: [
        {
          title: "Jim",
          type: "col-lg-4 col-md-6 portfolio-item p-2 veteran graphite",
          source: "assets/img/Veterans/Graphite/Jim.jpg",
        },
        {
          title: "Bill T.",
          type: "col-lg-4 col-md-6 portfolio-item p-2 veteran oil",
          source: "assets/img/Veterans/Oil/Bill T.jpg",
        },
        {
          title: "Chaplain Hawely",
          type: "col-lg-4 col-md-6 portfolio-item p-2 veteran oil",
          source: "assets/img/Veterans/Oil/Chaplain Hawely.jpg",
        },
        {
          title: "Dr Dubose",
          type: "col-lg-4 col-md-6 portfolio-item p-2 veteran oil",
          source: "assets/img/Veterans/Oil/Dr DuBose.jpg",
        },
        {
          title: "Jim T.",
          type: "col-lg-4 col-md-6 portfolio-item p-2 veteran oil",
          source: "assets/img/Veterans/Oil/Jim T.jpg",
        },
        {
          title: "Richard R.",
          type: "col-lg-4 col-md-6 portfolio-item p-2 veteran oil",
          source: "assets/img/Veterans/Oil/Richard R.jpg",
        },
        {
          title: "Brenda P.",
          type: "col-lg-4 col-md-6 portfolio-item p-2 veteran pastel",
          source: "assets/img/Veterans/Pastel/Brenda P.jpg",
        },
        {
          title: "George G.",
          type: "col-lg-4 col-md-6 portfolio-item p-2 veteran pastel",
          source: "assets/img/Veterans/Pastel/George G.jpg",
        },
        {
          title: "Mary",
          type: "col-lg-4 col-md-6 portfolio-item p-2 veteran pastel",
          source: "assets/img/Veterans/Pastel/Mary.jpg",
        },
      ],
    };
  },
  methods: {},
};
</script>
